import React from 'react';
import styled from 'styled-components';
import JarsSection from './JarsSection';
import { bootles } from './JarsData';

const Description = (
  <>
    Zapraszamy do zapoznania się z naszą ofertą gotowych wzorów butelek. Posiadamy w naszej ofercie
    pojemności <span>od 0,3 do 1 litra </span>oraz dwa rodzaje gwintów:{' '}
    <span>PCO (napojowy) i 28/410 (chemiczny).</span> Butelki te możemy wykorzystać w artykułach
    spożywczych, żywności dla zwierząt, przypraw, kosmetyków, chemii, wyrobów farmaceutycznych i
    innych. Posiadamy wszelkie pozwolenia do kontaktu naszych produktów z żywnością. Do każdego
    słoika można wybrać dowolny kolor nakrętki.
  </>
);

const StyledWrapperContainer = styled.div`
  max-width: 800px;
`;

const StyledH1 = styled.h1`
  text-align: center;
  color: #4567c1;
  font-size: 3.5rem;
  font-weight: 900;
  margin: 40px 0 0;

  @media (min-width: 600px) {
    font-size: 3.9rem;
  }
`;

const BootlesJarsSection = () => (
  <>
    <StyledWrapperContainer className="container" id="products">
      <StyledH1>Odkryj naszą ofertę</StyledH1>
    </StyledWrapperContainer>
    <JarsSection twoColumns jars={bootles} title="Butelki" description={Description}></JarsSection>
  </>
);

export default BootlesJarsSection;
