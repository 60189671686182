import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  width: 100%;
  margin: 50px 0 40px 0;

  @media (min-width: 600px) {
    margin: 90px 0 50px 0;
  }
`;

const StyledWrapperContainer = styled.div`
  max-width: ${({ secondary }) => (secondary ? '900px' : '600px')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledH2 = styled.div`
  text-align: center;
  color: #0f2165;
  font-weight: 900;
  font-size: 3rem;
  line-height: 3.4rem;

  @media (min-width: 600px) {
    font-size: ${({ secondary }) => (secondary ? '3rem' : '3.5rem')};
    line-height: ${({ secondary }) => (secondary ? '3.3rem' : '3.7rem')};
  }

  span {
    color: ${({ specialColor }) => specialColor};
  }
`;

const StyledContactDiv = styled.div`
  box-shadow: 0 0 2px 0 #4567c1;
  width: 90px;
  height: 35px;
  border: 2px solid #4567c1;
  position: relative;
  margin-top: 20px;

  span {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4567c1;
    font-size: 1.8rem;
  }

  > div {
    position: absolute;
    background-color: #4567c1;
    box-shadow: 0 0 2px 0 #4567c1;
    display: block;
    top: 50%;
    height: 2px;
    left: ${({ secondary }) => (secondary ? 'unset' : '100%')};
    right: ${({ secondary }) => (secondary ? '100%' : 'unset')};
    width: ${({ secondary }) => (secondary ? '220px' : '210px')};
    display: none;

    @media (min-width: 600px) {
      display: block;
    }

    > div {
      position: relative;

      ::before,
      ::after {
        content: '';
        position: absolute;
        background-color: #4567c1;
        box-shadow: 0 0 2px 0 #4567c1;
      }

      ::before {
        height: 54px;
        width: 2px;
        bottom: 100%;
        left: ${({ secondary }) => (secondary ? 'unset' : 'calc(100% - 2px)')};
      }

      ::after {
        height: 2px;
        width: 45px;
        bottom: 54px;
        right: 0;
        display: ${({ secondary }) => (secondary ? 'none' : 'block')};
      }
    }
  }
`;

const SpecialHeader = ({ children, specialColor, secondary }) => (
  <StyledWrapper>
    <StyledWrapperContainer secondary={secondary} className="container">
      <StyledH2 secondary={secondary} specialColor={specialColor}>
        {children}
      </StyledH2>
      <StyledContactDiv secondary={secondary}>
        <span>kontakt</span>
        <div>
          <div></div>
        </div>
      </StyledContactDiv>
    </StyledWrapperContainer>
  </StyledWrapper>
);

SpecialHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
  specialColor: PropTypes.string,
  secondary: PropTypes.bool,
};

SpecialHeader.defaultProps = {
  specialColor: '#fff',
  secondary: false,
};

export default SpecialHeader;
