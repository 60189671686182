import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form, Field, ErrorMessage } from 'formik';

export const StyledForm = styled(Form)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
`;

const StyledFieldCont = styled.div`
  height: ${({ isTextArea }) => (isTextArea ? '170px' : '48px')};
  padding: ${({ isTextArea }) => (isTextArea ? '12px' : '0 12px')};
  border-radius: 10px;
  border: 1px solid #f9be0e;
  box-shadow: 0 0 3px 1px #f9be0e;
  background-color: #505b88;
  grid-column: ${({ halfGrid }) => (halfGrid ? 'auto' : '1 / span 2')};
  position: relative;
  margin-bottom: 30px;
`;

const StyledInput = styled.input`
  color: #fff;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: center;
  display: block;
  height: 100%;
  background: transparent;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #fff;
    text-align: center;
  }
`;

const StyledTextArea = styled.textarea`
  color: #fff;
  font-size: 1.6rem;
  line-height: 2rem;
  text-align: left;
  display: block;
  height: 100%;
  background: transparent;
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }

  ::placeholder {
    color: #fff;
    text-align: center;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  position: absolute;
  top: calc(100% + 5px);
  left: 5px;
  color: #ff5252;
  font-size: 1.2rem;
  line-height: 1.8rem;
  white-space: nowrap;
`;

// eslint-disable-next-line react/prop-types
const FieldInput = ({ field, form, ...props }) => {
  return <StyledInput {...field} {...props} />;
};

// eslint-disable-next-line react/prop-types
const FieldTextArea = ({ field, form, ...props }) => {
  return <StyledTextArea {...field} {...props} />;
};

export const StyledField = ({ halfGrid, isTextArea, ...props }) => (
  <StyledFieldCont halfGrid={halfGrid} isTextArea={isTextArea}>
    <Field {...props} component={isTextArea ? FieldTextArea : FieldInput} />
    <StyledErrorMessage {...props} component="div" />
  </StyledFieldCont>
);

StyledField.propTypes = {
  halfGrid: PropTypes.bool,
  isTextArea: PropTypes.bool,
};

StyledField.defaultProps = {
  halfGrid: false,
  isTextArea: false,
};

export const StyledButton = styled.button`
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  box-shadow: 0 0 3px 1px #f9be0e;
  border: 1px solid #f9be0e;
  color: #f9be0e;
  text-transform: none;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border-radius: 4px;
  font-size: 1.4rem;
  grid-column: 1 / span 2;

  &:focus {
    outline: none;
  }
`;
