import React from 'react';
import styled from 'styled-components';
import sectionImage from 'assets/images/jars/square-jars-multi.png';
import SpecialHeader from 'components/molecules/SpecialHeader/SpecialHeader';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import JarsSection from './JarsSection';
import { squareJars } from './JarsData';

const Description = (
  <>
    Zapraszamy do zapoznania się z naszą ofertą gotowych wzorów słoików kwadratowych typu PET.
    Posiadamy w naszej ofercie pojemności <span>od 0,2l do 1l</span> oraz trzy rodzaje gwintów:{' '}
    <span>fi 63mm, fi 81mm oraz fi 85mm.</span> Opakowania te możemy wykorzystać w artykułach
    spożywczych, żywności dla zwierząt, przypraw, kosmetyków, chemii, wyrobów farmaceutycznych i
    innych. Posiadamy wszelkie pozwolenia do kontaktu naszych produktów z żywnością. Do każdego
    słoika można wybrać dowolny kolor nakrętki.
  </>
);

const SpecialHeaderText = (
  <>
    Nie znalazłeś odpowiadającego Ci wzoru? <br />
    Skontaktuj się z nami, a postaramy się znaleźć dla Ciebie najlepsze rozwiązanie.
  </>
);

const StyledWrapperContainer = styled.div`
  max-width: 800px;
  margin-bottom: 40px;
`;

const SquareJarsSection = () => (
  <>
    <JarsSection
      jars={squareJars}
      title="Słoiki kwadratowe"
      description={Description}
      sectionImage={sectionImage}
    ></JarsSection>
    <SpecialHeader secondary specialColor="#F9BE0E">
      {SpecialHeaderText}
    </SpecialHeader>
    <StyledWrapperContainer className="container">
      <Paragraph color="#0f2165" specialColor="#F9BE0E">
        Możemy wytworzyć dla Ciebie opakowanie z tworzywa sztucznego typu PET o kształcie, który
        najbardziej pasuje do Twojego produktu. Warunki co do pokrycia kosztów formy rozdmuchowej są
        ustalane indywidualnie i w zależności od wielkości zamówienia. Wyślij do nas zdjęcie, szkic
        lub rysunek Twojego wymarzonego słoika.
      </Paragraph>
    </StyledWrapperContainer>
  </>
);

export default SquareJarsSection;
