import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import JarItem from './JarItem';

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

const StyledH2 = styled.h2`
  text-align: center;
  font-weight: 900;
  color: #0f2165;
  font-size: 2.9rem;
  line-height: 3.2rem;
  margin: 20px 0;

  @media (min-width: 600px) {
    font-size: 3rem;
    line-height: 3.3rem;
  }
`;

const StyledSectionImg = styled.img`
  width: 100%;
`;

const StyledGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px 0;

  @media (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 800px;
    gap: 70px 30px;
  }

  @media (min-width: 960px) {
    grid-template-columns: ${({ twoColumns }) =>
      twoColumns ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)'};
    max-width: ${({ twoColumns }) => (twoColumns ? '800px' : 'unset')};
  }
`;

const StyledParagraph = styled(Paragraph)`
  margin: 30px 0 60px 0;
  max-width: 800px;
  margin-top: ${({ noTopMargin }) => (noTopMargin ? '0px' : '30px')};
`;

const JarsSection = ({ jars, title, description, sectionImage, twoColumns }) => (
  <StyledWrapper>
    <StyledWrapperContainer className="container">
      <StyledH2>{title}</StyledH2>
      {sectionImage && <StyledSectionImg src={sectionImage}></StyledSectionImg>}
      <StyledParagraph noTopMargin={!sectionImage} color="#0f2165" specialColor="#F9BE0E">
        {description}
      </StyledParagraph>
      <StyledGrid twoColumns={twoColumns}>
        {jars.map(({ name, cap, lid, image }) => (
          <JarItem name={name} cap={cap} lid={lid} image={image} key={name} />
        ))}
      </StyledGrid>
    </StyledWrapperContainer>
  </StyledWrapper>
);

JarsSection.propTypes = {
  jars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      cap: PropTypes.string.isRequired,
      lid: PropTypes.string,
      image: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.string.isRequired,
  description: PropTypes.element.isRequired,
  sectionImage: PropTypes.string,
  twoColumns: PropTypes.bool,
};

JarsSection.defaultProps = {
  jars: [],
  sectionImage: null,
  twoColumns: false,
};

export default JarsSection;
