import React from 'react';
import styled from 'styled-components';
import Paragraph from 'components/atoms/Paragraph/Paragraph';
import BluredDiv from 'components/atoms/BluredDiv/BluredDiv';
import aboutImage from 'assets/images/about-image.jpg';

const StyledWrapper = styled.div`
  width: 100%;
  background: radial-gradient(circle, #11246e 0, #000 100%);
  padding: 40px 0;
`;

const StyledWrapperContainer = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImageDiv = styled(BluredDiv)`
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  border-radius: 0;

  ::after {
    border-radius: 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
`;

const StyledH1 = styled.h1`
  color: #fff;
  font-size: 3.6rem;
  font-weight: 900;
  text-align: center;
  filter: drop-shadow(0 0 2px #f9be0e);
  margin-top: 0;

  @media (min-width: 600px) {
    font-size: 3.9rem;
  }
`;

const StyledRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 30px;
  flex-direction: column;

  @media (min-width: 600px) {
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
  }
`;

const StyledInsideRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  color: #fff;
  line-height: 3rem;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    margin-bottom: 0;
  }
`;

const StyledShadowSpan = styled.div`
  line-height: 1.5rem;
  font-size: 6rem;
  font-weight: 900;
  margin-right: 3px;
  filter: drop-shadow(0 0 3px #f9be0e);
`;

const AboutSection = () => (
  <StyledWrapper id="about">
    <StyledH1>O firmie</StyledH1>
    <StyledWrapperContainer className="container">
      <StyledImageDiv>
        <StyledImage src={aboutImage}></StyledImage>
      </StyledImageDiv>
      <Paragraph specialColor="#F9BE0E">
        Firma PET-r jest na rynku już od 13 lat. Pan Piotr Izydorek – właściciel firmy pracuje przy
        wydmuchu opakowań PET od początku istnienia branży na naszym polskim rynku ( wczesne lata
        90’te ). Jego wieloletnie doświadczenie jest gwarantem jakości każdego naszego produktu. Nie
        posiadamy rozbudowanych struktur w firmie co skutkuje szybkim procesem decyzyjnym podczas
        wdrażania nowych produktów szytych na miarę pod klienta. Zakład produkcyjny mieści się w
        Poznaniu. Jesteśmy firmą rodzinną. Cały czas prężnie się rozwijamy i stawiamy na najwyższą
        jakość naszych produktów. Nasi pracownicy posiadają trzydziestoletnie doświadczenie w branży
        produkcji opakowań typu PET.
      </Paragraph>
      <StyledRow>
        <StyledInsideRow>
          <StyledShadowSpan>13</StyledShadowSpan>lat na rynku
        </StyledInsideRow>
        <StyledInsideRow>
          <StyledShadowSpan>30</StyledShadowSpan>lat doświadczenia <br />
          pracowników
        </StyledInsideRow>
      </StyledRow>
    </StyledWrapperContainer>
  </StyledWrapper>
);

export default AboutSection;
