import React from 'react';
import styled from 'styled-components';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const StyledWrapper = styled.div`
  width: 100%;
  background: radial-gradient(circle, #11246e 0, #000 100%);
  padding: 40px 0;
`;

const StyledWrapperContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: 600px) {
    align-items: center;
  }

  @media (min-width: 960px) {
    flex-direction: row;
  }
`;

const StyledContactInfoContainer = styled.div`
  max-width: 350px;
  flex: 0 0 350px;
  margin-top: 50px;

  @media (min-width: 600px) {
    margin: 50px 0 0 50px;
  }
`;

const ContactSection = () => (
  <StyledWrapper id="contact">
    <StyledWrapperContainer className="container">
      <ContactForm></ContactForm>
      <StyledContactInfoContainer>
        <ContactInfo></ContactInfo>
      </StyledContactInfoContainer>
    </StyledWrapperContainer>
  </StyledWrapper>
);

export default ContactSection;
