import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { StyledForm, StyledField, StyledButton } from './FormElements';

const BasicForm = () => (
  <Formik
    initialValues={{ firstName: '', lastName: '', email: '', phone: '', message: '' }}
    validate={values => {
      const errors = {};
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Niepoprawny adres email';
      }
      Object.keys(values).forEach(property => {
        if (!values[property]) errors[property] = 'To pole jest wymagane';
      });
      return errors;
    }}
    onSubmit={async values => {
      try {
        await axios.post('/backend/contact.php', { ...values });
      } catch (e) {
        console.log(e);
      }
    }}
  >
    {({ isSubmitting }) => (
      <StyledForm>
        <StyledField type="text" name="firstName" placeholder="imię" halfGrid />
        <StyledField type="text" name="lastName" placeholder="nazwisko" halfGrid />
        <StyledField type="email" name="email" placeholder="e-mail" halfGrid />
        <StyledField type="text" name="phone" placeholder="telefon" halfGrid />
        <StyledField type="text" name="message" placeholder="wiadomość" isTextArea />
        <StyledButton type="submit" disabled={isSubmitting}>
          Wyślij
        </StyledButton>
      </StyledForm>
    )}
  </Formik>
);

export default BasicForm;
