import styled, { css } from 'styled-components';

const BluredDiv = styled.div`
  background: linear-gradient(
    90deg,
    #0a0f2f,
    rgba(10, 15, 47, 0.8) 20%,
    rgba(10, 15, 47, 0.65) 50%,
    rgba(10, 15, 47, 0.8) 80%,
    #0a0f2f
  );
  ${({ deepper }) =>
    deepper &&
    css`
      background: linear-gradient(
        90deg,
        #0a0f2f,
        rgba(10, 15, 47, 0.2) 50%,
        rgba(10, 15, 47, 0.65) 75%,
        #0a0f2f
      );
    `}
  position: relative;
  border-radius: 10px;
  border: 2px solid #fff;
  width: fit-content;
  text-align: center;

  ::after {
    content: '';
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    bottom: -2px;
    border-radius: 10px;
    box-shadow: 0 0 9px 4px #f9be0e;
  }
`;

export default BluredDiv;
