import React from 'react';
import styled from 'styled-components';
import Paragraph from 'components/atoms/Paragraph/Paragraph';

const StyledWrapper = styled.div`
  width: 100%;
  background: radial-gradient(circle, #11246e 0, #000 100%);
  padding: 40px 0;
`;

const StyledWrapperContainer = styled.div`
  max-width: 800px;
`;

const DivSection = () => (
  <StyledWrapper>
    <StyledWrapperContainer className="container">
      <Paragraph specialColor="#F9BE0E">
        Jesteśmy <span>producentem opakowań</span> typu <span>PET</span> specjalizującym się w
        szczególności w produkcji słoików plastikowych o pojemnościach{' '}
        <span>od 0,1 do 2 litrów.</span> Nasze produkty znajdują zastosowanie w takich{' '}
        <span>
          branżach jak artykuły spożywcze, chemia gospodarcza, kosmetyki, wyroby
          farmaceutyczne,napoje i inne.{' '}
        </span>
        Wszystkie produkty powstają w technologii wydmuchu. <br />{' '}
        <span>Mamy 13 lat doświadczenia</span> w przetwórstwie tworzyw sztucznych. Przykładamy
        największą wagę do naszych produktów co owocuje najwyższą jakością. Chętnie pomożemy w
        doborze odpowiedniego opakowania oraz zamknięcia dla Twojego produktu.
      </Paragraph>
    </StyledWrapperContainer>
  </StyledWrapper>
);

export default DivSection;
