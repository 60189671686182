import imageBootle01 from 'assets/images/jars/bootles/bootle-1.png';
import imageBootle02 from 'assets/images/jars/bootles/bootle-2.png';
import imageBootle03 from 'assets/images/jars/bootles/bootle-3.png';
import imageBootle04 from 'assets/images/jars/bootles/bootle-4.png';

import imageRoundedJar01 from 'assets/images/jars/round-jars/round-jar-1.png';
import imageRoundedJar02 from 'assets/images/jars/round-jars/round-jar-2.png';
import imageRoundedJar03 from 'assets/images/jars/round-jars/round-jar-3.png';
import imageRoundedJar04 from 'assets/images/jars/round-jars/round-jar-4.png';
import imageRoundedJar05 from 'assets/images/jars/round-jars/round-jar-5.png';
import imageRoundedJar06 from 'assets/images/jars/round-jars/round-jar-6.png';
import imageRoundedJar07 from 'assets/images/jars/round-jars/round-jar-7.png';
import imageRoundedJar08 from 'assets/images/jars/round-jars/round-jar-8.png';
import imageRoundedJar09 from 'assets/images/jars/round-jars/round-jar-9.png';
import imageRoundedJar10 from 'assets/images/jars/round-jars/round-jar-10.png';
import imageRoundedJar11 from 'assets/images/jars/round-jars/round-jar-11.png';
import imageRoundedJar12 from 'assets/images/jars/round-jars/round-jar-12.png';
import imageRoundedJar13 from 'assets/images/jars/round-jars/round-jar-13.png';
import imageRoundedJar14 from 'assets/images/jars/round-jars/round-jar-14.png';
import imageRoundedJar15 from 'assets/images/jars/round-jars/round-jar-15.png';
import imageRoundedJar16 from 'assets/images/jars/round-jars/round-jar-16.png';
import imageRoundedJar17 from 'assets/images/jars/round-jars/round-jar-17.png';
import imageRoundedJar18 from 'assets/images/jars/round-jars/round-jar-18.png';
import imageRoundedJar19 from 'assets/images/jars/round-jars/round-jar-19.png';
import imageRoundedJar20 from 'assets/images/jars/round-jars/round-jar-20.png';
import imageRoundedJar21 from 'assets/images/jars/round-jars/round-jar-21.png';

import imageSquareJar01 from 'assets/images/jars/square-jars/square-jar-1.png';
import imageSquareJar02 from 'assets/images/jars/square-jars/square-jar-2.png';
import imageSquareJar03 from 'assets/images/jars/square-jars/square-jar-3.png';
import imageSquareJar04 from 'assets/images/jars/square-jars/square-jar-4.png';
import imageSquareJar05 from 'assets/images/jars/square-jars/square-jar-5.png';
import imageSquareJar06 from 'assets/images/jars/square-jars/square-jar-6.png';
import imageSquareJar07 from 'assets/images/jars/square-jars/square-jar-7.png';
import imageSquareJar08 from 'assets/images/jars/square-jars/square-jar-8.png';
import imageSquareJar09 from 'assets/images/jars/square-jars/square-jar-9.png';
import imageSquareJar10 from 'assets/images/jars/square-jars/square-jar-10.png';
import imageSquareJar11 from 'assets/images/jars/square-jars/square-jar-11.png';

export const bootles = [
  {
    name: 'B01',
    cap: '350ml',
    image: imageBootle01,
  },
  {
    name: 'B02',
    cap: '500ml',
    image: imageBootle02,
  },
  {
    name: 'B03',
    cap: '750ml',
    image: imageBootle03,
  },
  {
    name: 'B04',
    cap: '1l',
    image: imageBootle04,
  },
];

export const roundedJars = [
  {
    name: 'S01',
    cap: '100ml',
    lid: '63mm',
    image: imageRoundedJar01,
  },
  {
    name: 'S02',
    cap: '140ml',
    lid: '63mm',
    image: imageRoundedJar02,
  },
  {
    name: 'S03',
    cap: '200ml',
    lid: '63mm',
    image: imageRoundedJar03,
  },
  {
    name: 'S04',
    cap: '290ml',
    lid: '63mm',
    image: imageRoundedJar04,
  },
  {
    name: 'S05',
    cap: '300ml',
    lid: '43mm',
    image: imageRoundedJar05,
  },
  {
    name: 'S06',
    cap: '300ml',
    lid: '63mm',
    image: imageRoundedJar06,
  },
  {
    name: 'S07',
    cap: '300ml',
    lid: '63mm',
    image: imageRoundedJar07,
  },
  {
    name: 'S08',
    cap: '350ml',
    lid: '63mm',
    image: imageRoundedJar08,
  },
  {
    name: 'S09',
    cap: '370ml',
    lid: '63mm',
    image: imageRoundedJar09,
  },
  {
    name: 'S10',
    cap: '500ml',
    lid: '63mm',
    image: imageRoundedJar10,
  },
  {
    name: 'S11',
    cap: '500ml',
    lid: '63mm',
    image: imageRoundedJar11,
  },
  {
    name: 'S12',
    cap: '700ml',
    lid: '63mm',
    image: imageRoundedJar12,
  },
  {
    name: 'S013',
    cap: '750ml',
    lid: '85mm',
    image: imageRoundedJar13,
  },
  {
    name: 'S14',
    cap: '800ml',
    lid: '85mm',
    image: imageRoundedJar14,
  },
  {
    name: 'S15',
    cap: '900ml',
    lid: '81mm',
    image: imageRoundedJar15,
  },
  {
    name: 'S16',
    cap: '1l',
    lid: '85mm',
    image: imageRoundedJar16,
  },
  {
    name: 'S17',
    cap: '1,2l',
    lid: '81mm',
    image: imageRoundedJar17,
  },
  {
    name: 'S18',
    cap: '1,3ml',
    lid: '81mm',
    image: imageRoundedJar18,
  },
  {
    name: 'S19',
    cap: '1,5l',
    lid: '85mm',
    image: imageRoundedJar19,
  },
  {
    name: 'S20',
    cap: '1,7l',
    lid: '63mm',
    image: imageRoundedJar20,
  },
  {
    name: 'S21',
    cap: '2l',
    lid: '85mm',
    image: imageRoundedJar21,
  },
];

export const squareJars = [
  {
    name: 'SK1',
    cap: '150ml',
    lid: '48mm',
    image: imageSquareJar01,
  },
  {
    name: 'SK2',
    cap: '200ml',
    lid: '63mm',
    image: imageSquareJar02,
  },
  {
    name: 'SK3',
    cap: '300ml',
    lid: '63mm',
    image: imageSquareJar03,
  },
  {
    name: 'SK4',
    cap: '350ml',
    lid: '64mm',
    image: imageSquareJar04,
  },
  {
    name: 'SK5',
    cap: '500ml',
    lid: '63mm',
    image: imageSquareJar05,
  },
  {
    name: 'SK6',
    cap: '500ml',
    lid: '63mm',
    image: imageSquareJar06,
  },
  {
    name: 'SK7',
    cap: '800ml',
    lid: '63mm',
    image: imageSquareJar07,
  },
  {
    name: 'SK8',
    cap: '1,5l',
    lid: '63mm',
    image: imageSquareJar08,
  },
  {
    name: 'SK9',
    cap: '1l',
    lid: '63mm',
    image: imageSquareJar09,
  },
  {
    name: 'SK10',
    cap: '1l',
    lid: '81mm',
    image: imageSquareJar10,
  },
  {
    name: 'SK11',
    cap: '1l',
    lid: '85mm',
    image: imageSquareJar11,
  },
];
