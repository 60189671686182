import React from 'react';
import styled from 'styled-components';
import logoImage from 'assets/images/logo.png';
import { Link } from 'react-scroll';

const StyledWrapper = styled.nav`
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px 0;
  width: 100%;
  height: 130px;
  background-color: transparent;

  @media (min-width: 600px) {
    height: 160px;
  }
`;

const StyledWrapperContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const StyledLogo = styled.img`
  height: 100%;
`;

const StyledMenu = styled.ul`
  list-style: none;
  margin: 25px 0 0 60px;
  padding: 0;
  display: none;

  @media (min-width: 960px) {
    display: flex;
  }
`;

const StyledMenuItem = styled(Link)`
  margin-right: 48px;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.2rem;
  font-weight: 400;
  cursor: pointer;

  :last-of-type {
    margin-right: 0;
  }
`;

const TobBar = () => (
  <StyledWrapper>
    <StyledWrapperContainer className="container">
      <StyledLogo src={logoImage} />
      <StyledMenu>
        <StyledMenuItem to="products" spy smooth offset={50} duration={500}>
          Produkty
        </StyledMenuItem>
        <StyledMenuItem to="about" spy smooth offset={50} duration={500}>
          O firmie
        </StyledMenuItem>
        <StyledMenuItem to="contact" spy smooth offset={50} duration={500}>
          Kontakt
        </StyledMenuItem>
      </StyledMenu>
    </StyledWrapperContainer>
  </StyledWrapper>
);

export default TobBar;
