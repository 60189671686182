import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styled from 'styled-components';

const StyledModal = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: none;

  &.is-active {
    display: flex;
  }
`;

const StyledModalBackground = styled.div`
  background-color: rgba(10, 10, 10, 0.86);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const StyledModalContent = styled.div`
  overflow: auto;
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 60px);
  width: 600px;
  max-width: calc(100% - 30px);
  background-color: #fff;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.6);
`;

const StyledModalContentImage = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

const StyledModalCloseButton = styled.button`
  background-color: rgba(10, 10, 10, 0.3);
  height: 35px;
  position: fixed;
  right: 20px;
  top: 40px;
  width: 35px;
  cursor: pointer;
  border: none;
  border-radius: 10px;

  :hover {
    background-color: rgba(10, 10, 10, 0.5);
  }

  ::before,
  ::after {
    content: '';
    background-color: #fff;
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
  }

  ::before {
    height: 2px;
    width: 50%;
  }

  ::after {
    height: 50%;
    width: 2px;
  }
`;

const ImageModal = ({ image, isCollapsed, onClose }) => {
  const classList = cx({ 'is-active': isCollapsed });

  return (
    <StyledModal className={classList}>
      <StyledModalBackground />
      <StyledModalContent>
        <StyledModalContentImage src={image} alt="" />
      </StyledModalContent>
      <StyledModalCloseButton onClick={onClose} />
    </StyledModal>
  );
};

ImageModal.propTypes = {
  image: PropTypes.string.isRequired,
  isCollapsed: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

ImageModal.defaultProps = {
  isCollapsed: false,
};

export default ImageModal;
