import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BluredDiv from 'components/atoms/BluredDiv/BluredDiv';
import ImageModal from 'components/molecules/ImageModal/ImageModal';

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const StyledImageDiv = styled.div`
  margin: 0 auto;
  max-width: 180px;
  height: 180px;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const StyledBluredDiv = styled(BluredDiv)`
  width: 100%;
  height: 100px;
  padding: 10px;
  text-align: center;
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 1.6rem;
  line-height: 2.1rem;
  margin-top: 25px;
  color: #fff;
  font-weight: 900;
`;

const StyledNameField = styled.div`
  font-size: 2.6rem;
`;

const StyledParametersField = styled.div`
  font-size: 1.6rem;
  line-height: 2.1rem;
  margin-left: 15px;
  flex: 1;
`;

const StyledSingleParameterField = styled.div`
  span {
    text-transform: uppercase;
    color: #f9be0e;
  }
`;

const JarItem = ({ name, cap, lid, image }) => {
  const [isCollapsed, setCollapsed] = useState(false);
  const onCloseClick = () => {
    setCollapsed(false);
    document.querySelector('html').style.overflow = 'auto';
  };
  const onOpenModal = () => {
    setCollapsed(true);
    document.querySelector('html').style.overflow = 'hidden';
    document.addEventListener('keydown', e => {
      if (e.code === 'Escape') {
        setCollapsed(false);
        document.querySelector('html').style.overflow = 'auto';
      }
    });
  };

  return (
    <StyledWrapper>
      <StyledImageDiv>
        <StyledImage onClick={onOpenModal} src={image}></StyledImage>
      </StyledImageDiv>
      <StyledBluredDiv>
        <StyledNameField>{name}</StyledNameField>
        <StyledParametersField>
          <StyledSingleParameterField>
            <span>Pojemność: </span>
            {cap}
          </StyledSingleParameterField>
          {lid && (
            <StyledSingleParameterField>
              <span>Gwint: </span>
              {lid}
            </StyledSingleParameterField>
          )}
        </StyledParametersField>
      </StyledBluredDiv>
      <ImageModal isCollapsed={isCollapsed} onClose={onCloseClick} image={image} />
    </StyledWrapper>
  );
};

JarItem.propTypes = {
  name: PropTypes.string.isRequired,
  cap: PropTypes.string.isRequired,
  lid: PropTypes.string,
  image: PropTypes.string.isRequired,
};

JarItem.defaultProps = {
  lid: '',
};

export default JarItem;
