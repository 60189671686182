import styled from 'styled-components';

const ContactHeader = styled.h2`
  text-align: left;
  margin: 20px 0;
  color: #f9be0e;
  font-size: 3rem;
  line-height: 3.3rem;
  font-weight: 900;
`;

export default ContactHeader;
