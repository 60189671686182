import PropTypes from 'prop-types';
import styled from 'styled-components';

const Paragraph = styled.p`
  color: ${({ color }) => color};
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-align: justify;
  margin: 0;

  @media (min-width: 600px) {
    line-height: 2.7rem;
    font-size: 1.8rem;
  }

  span {
    color: ${({ specialColor }) => specialColor};
  }
`;

Paragraph.propTypes = {
  specialColor: PropTypes.string,
  color: PropTypes.string,
};

Paragraph.defaultProps = {
  specialColor: '#fff',
  color: '#fff',
};

export default Paragraph;
