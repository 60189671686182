import React from 'react';
import styled from 'styled-components';
import ContactHeader from './ContactHeader';
import BasicForm from './BasicForm';

const StyledWrapper = styled.div``;

const StyledH3 = styled.h3`
  color: #fff;
  font-size: 2.1rem;
  margin-bottom: 30px;
  font-weight: 700;
`;

const ContactForm = () => (
  <StyledWrapper>
    <ContactHeader>Masz pytania odnośnie naszych produktów?</ContactHeader>
    <StyledH3>Napisz do nas!</StyledH3>
    <BasicForm />
  </StyledWrapper>
);

export default ContactForm;
