export const theme = {
  color: {
    black: 'hsl(0, 0%, 0%)',
    white: 'hsl(360, 100%, 100%)',
    primary: 'hsl(360, 100%, 50%)',
    secondary: 'hsl(49, 100%, 50%)',
    xxx: '#FFF',
  },
  fontWeight: {
    light: 300,
    bold: 600,
  },
  fontSize: {
    xxs: '1rem',
    xs: '1.2rem',
    s: '1.6rem',
    m: '2.1rem',
    l: '2.4rem',
    xl: '4rem',
  },
};
