import React from 'react';
import MainTemplate from 'templates/MainTemplate';
import FrontSection from 'components/organisms/FrontSection/FrontSection';
import TopBar from 'components/organisms/TopBar/TopBar';
import DivSection from 'components/organisms/DivSection/DivSection';
import BootlesJarsSection from 'components/organisms/JarsSection/BootlesJarsSection';
import RoundedJarsSection from 'components/organisms/JarsSection/RoundedJarsSection';
import SquareJarsSection from 'components/organisms/JarsSection/SquareJarsSection';
import AboutSection from 'components/organisms/AboutSection/AboutSection';
import ContactSection from 'components/organisms/ContactSection/ContactSection';

const Root = () => (
  <MainTemplate>
    <FrontSection />
    <TopBar />
    <DivSection />
    <BootlesJarsSection />
    <RoundedJarsSection />
    <SquareJarsSection />
    <AboutSection />
    <ContactSection />
  </MainTemplate>
);

export default Root;
