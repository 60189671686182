import React from 'react';
import sectionImage from 'assets/images/jars/round-jars-multi.png';
import SpecialHeader from 'components/molecules/SpecialHeader/SpecialHeader';
import JarsSection from './JarsSection';
import { roundedJars } from './JarsData';

const Description = (
  <>
    Zapraszamy do zapoznania się z naszą ofertą gotowych wzorów słoików okrągłych typu PET.
    Posiadamy w naszej ofercie pojemności <span>od 0,1l do 2l </span>oraz trzy rodzaje gwintów:{' '}
    <span>fi 63mm, fi 81mm oraz fi 85mm.</span> Opakowania te możemy wykorzystać w artykułach
    spożywczych, żywności dla zwierząt, przypraw, kosmetyków, chemii, wyrobów farmaceutycznych i
    innych. Posiadamy wszelkie pozwolenia do kontaktu naszych produktów z żywnością. Do każdego
    słoika można wybrać dowolny kolor nakrętki.
  </>
);
const SpecialHeaderText = (
  <>
    Sprawdź nasze gotowe wzory i zamów <span>bezpłatne próbki.</span>
    <br /> Skontaktuj się z nami!
  </>
);

const RoundedJarsSection = () => (
  <>
    <JarsSection
      jars={roundedJars}
      title="Słoiki okrągłe"
      description={Description}
      sectionImage={sectionImage}
    ></JarsSection>
    <SpecialHeader specialColor="#F9BE0E">{SpecialHeaderText}</SpecialHeader>
  </>
);

export default RoundedJarsSection;
