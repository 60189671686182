import React from 'react';
import styled from 'styled-components';
import ContactHeader from './ContactHeader';

const StyledWrapper = styled.div`
  width: 100%;
  line-height: 2.6rem;
`;

const StyledContactInfoItem = styled.div`
  font-size: 1.6rem;
  color: #fff;
  line-height: 2.6rem;
  white-space: nowrap;
`;

const ContactInfo = () => (
  <StyledWrapper>
    <ContactHeader>Informacje kontaktowe:</ContactHeader>
    <StyledContactInfoItem>PET-r Piotr Izydorek</StyledContactInfoItem>
    <StyledContactInfoItem>ul. Rolna 9</StyledContactInfoItem>
    <StyledContactInfoItem>62-020 Swarzędz</StyledContactInfoItem>
    <StyledContactInfoItem>NIP: 7771320901</StyledContactInfoItem>
    <br />
    <StyledContactInfoItem>Telefon: +48 606 992 158</StyledContactInfoItem>
    <StyledContactInfoItem>E-mail: pet_r.opakowania@op.pl</StyledContactInfoItem>
    <br />
    <StyledContactInfoItem>Hala produkcyjna:</StyledContactInfoItem>
    <StyledContactInfoItem>ul. Romana Maya 1</StyledContactInfoItem>
    <StyledContactInfoItem>61-371 Poznań</StyledContactInfoItem>
  </StyledWrapper>
);

export default ContactInfo;
