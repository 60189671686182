import React from 'react';
import styled from 'styled-components';
import bgImage from 'assets/images/background-image.jpg';
import BluredDiv from 'components/atoms/BluredDiv/BluredDiv';

const StyledWrapper = styled.div`
  height: 100vh;
  min-width: 100%;
  background: center / cover no-repeat url(${bgImage});
`;

const StyledWrapperContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (min-width: 600px) {
    align-items: flex-end;
  }
`;

const StyledDivsColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: fit-content;
  top: 15%;

  @media (min-width: 600px) {
    top: unset;
  }
`;

const StyledBluredDiv = styled(BluredDiv)`
  color: #fff;
  max-width: ${({ smaller }) => (smaller ? '520px' : '860px')};
  font-weight: 900;
  font-size: 2.7rem;
  line-height: 3rem;
  padding: 10px;
  margin-bottom: 60px;

  @media (min-width: 600px) {
    font-size: ${({ smaller }) => (smaller ? '3.5rem' : '4.5rem')};
    line-height: ${({ smaller }) => (smaller ? '4.5rem' : '5.5rem')};
    padding: 25px 45px;
    margin-bottom: 35px;
  }
`;

const FrontSection = () => (
  <StyledWrapper>
    <StyledWrapperContainer className="container">
      <StyledDivsColumn>
        <StyledBluredDiv deepper>
          Produkujemy trwałe opakowania plastikowe dla twoich produktów
        </StyledBluredDiv>
        <StyledBluredDiv smaller deepper>
          Skorzystaj z naszej oferty słoików i butelek z tworzywa PET
        </StyledBluredDiv>
      </StyledDivsColumn>
    </StyledWrapperContainer>
  </StyledWrapper>
);

export default FrontSection;
